import createPersistedState from "vuex-persistedstate";
// import * as Cookies from "js-cookie";
// NOTE: Maybe remove "js-cookie"

let _ = undefined;
// if (process.client) {
_ = ({ store }) => {
    window.onNuxtReady(() => {
        createPersistedState({
            // key: 'yourkey',
            paths: ["auth"],
            storage: window.sessionStorage,
            // storage: {
            //   getItem: (key) => Cookies.get(key),
            //   // Please see https://github.com/js-cookie/js-cookie#json, on how to handle JSON.
            //   setItem: (key, value) =>
            //     Cookies.set(key, value, {
            //       expires: 3,
            //       secure: process.env.NODE_ENV === "production",
            //       sameSite: "strict",
            //     }),
            //   removeItem: (key) => Cookies.remove(key),
            // },
        })(store);
    });
};
// }
export default _;
