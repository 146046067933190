import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _df0f102e = () => interopDefault(import('../pages/about/index.vue' /* webpackChunkName: "pages/about/index" */))
const _dd26a170 = () => interopDefault(import('../pages/blocked/index.vue' /* webpackChunkName: "pages/blocked/index" */))
const _54f0825c = () => interopDefault(import('../pages/chat/index.vue' /* webpackChunkName: "pages/chat/index" */))
const _de890d3a = () => interopDefault(import('../pages/communities/index.vue' /* webpackChunkName: "pages/communities/index" */))
const _51c615d0 = () => interopDefault(import('../pages/components/index.vue' /* webpackChunkName: "pages/components/index" */))
const _c6465f80 = () => interopDefault(import('../pages/content-policy/index.vue' /* webpackChunkName: "pages/content-policy/index" */))
const _d280caba = () => interopDefault(import('../pages/discover/index.vue' /* webpackChunkName: "pages/discover/index" */))
const _1f7e85f1 = () => interopDefault(import('../pages/followers/index.vue' /* webpackChunkName: "pages/followers/index" */))
const _6bbd7810 = () => interopDefault(import('../pages/forgotpassword/index.vue' /* webpackChunkName: "pages/forgotpassword/index" */))
const _d13dbe76 = () => interopDefault(import('../pages/login/index.vue' /* webpackChunkName: "pages/login/index" */))
const _54dab7ce = () => interopDefault(import('../pages/menu/index.vue' /* webpackChunkName: "pages/menu/index" */))
const _8521baf8 = () => interopDefault(import('../pages/notifications/index.vue' /* webpackChunkName: "pages/notifications/index" */))
const _221b0111 = () => interopDefault(import('../pages/privacy-policy/index.vue' /* webpackChunkName: "pages/privacy-policy/index" */))
const _46d1b0dd = () => interopDefault(import('../pages/register/index.vue' /* webpackChunkName: "pages/register/index" */))
const _a15c397c = () => interopDefault(import('../pages/user-agreement/index.vue' /* webpackChunkName: "pages/user-agreement/index" */))
const _2bd2b3f2 = () => interopDefault(import('../pages/communities/add.vue' /* webpackChunkName: "pages/communities/add" */))
const _00f8dee4 = () => interopDefault(import('../pages/components/post-inline.vue' /* webpackChunkName: "pages/components/post-inline" */))
const _7e228e9a = () => interopDefault(import('../pages/menu/posts.vue' /* webpackChunkName: "pages/menu/posts" */))
const _3eb9aa64 = () => interopDefault(import('../pages/communities/_id/index.vue' /* webpackChunkName: "pages/communities/_id/index" */))
const _2a7a032a = () => interopDefault(import('../pages/user/_username/index.vue' /* webpackChunkName: "pages/user/_username/index" */))
const _1419810e = () => interopDefault(import('../pages/communities/_id/admin/index.vue' /* webpackChunkName: "pages/communities/_id/admin/index" */))
const _ed7d3284 = () => interopDefault(import('../pages/communities/_id/edit.vue' /* webpackChunkName: "pages/communities/_id/edit" */))
const _5143393d = () => interopDefault(import('../pages/communities/_id/invite.vue' /* webpackChunkName: "pages/communities/_id/invite" */))
const _f22272d6 = () => interopDefault(import('../pages/communities/_id/members.vue' /* webpackChunkName: "pages/communities/_id/members" */))
const _ec3a0510 = () => interopDefault(import('../pages/communities/_id/requests.vue' /* webpackChunkName: "pages/communities/_id/requests" */))
const _e44c9774 = () => interopDefault(import('../pages/user/_username/createprofile.vue' /* webpackChunkName: "pages/user/_username/createprofile" */))
const _1e3a2b81 = () => interopDefault(import('../pages/user/_username/edit.vue' /* webpackChunkName: "pages/user/_username/edit" */))
const _2d8977ae = () => interopDefault(import('../pages/user/_username/followers.vue' /* webpackChunkName: "pages/user/_username/followers" */))
const _0cb42fac = () => interopDefault(import('../pages/user/_username/following.vue' /* webpackChunkName: "pages/user/_username/following" */))
const _eeafed24 = () => interopDefault(import('../pages/user/_username/friends.vue' /* webpackChunkName: "pages/user/_username/friends" */))
const _b60c435a = () => interopDefault(import('../pages/communities/_id/admin/pending.vue' /* webpackChunkName: "pages/communities/_id/admin/pending" */))
const _34e764c7 = () => interopDefault(import('../pages/communities/_id/admin/reported.vue' /* webpackChunkName: "pages/communities/_id/admin/reported" */))
const _01463ed2 = () => interopDefault(import('../pages/user/_username/posts/_id.vue' /* webpackChunkName: "pages/user/_username/posts/_id" */))
const _7a135eaa = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

// TODO: remove in Nuxt 3
const emptyFn = () => {}
const originalPush = Router.prototype.push
Router.prototype.push = function push (location, onComplete = emptyFn, onAbort) {
  return originalPush.call(this, location, onComplete, onAbort)
}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _df0f102e,
    name: "about"
  }, {
    path: "/blocked",
    component: _dd26a170,
    name: "blocked"
  }, {
    path: "/chat",
    component: _54f0825c,
    name: "chat"
  }, {
    path: "/communities",
    component: _de890d3a,
    name: "communities"
  }, {
    path: "/components",
    component: _51c615d0,
    name: "components"
  }, {
    path: "/content-policy",
    component: _c6465f80,
    name: "content-policy"
  }, {
    path: "/discover",
    component: _d280caba,
    name: "discover"
  }, {
    path: "/followers",
    component: _1f7e85f1,
    name: "followers"
  }, {
    path: "/forgotpassword",
    component: _6bbd7810,
    name: "forgotpassword"
  }, {
    path: "/login",
    component: _d13dbe76,
    name: "login"
  }, {
    path: "/menu",
    component: _54dab7ce,
    name: "menu"
  }, {
    path: "/notifications",
    component: _8521baf8,
    name: "notifications"
  }, {
    path: "/privacy-policy",
    component: _221b0111,
    name: "privacy-policy"
  }, {
    path: "/register",
    component: _46d1b0dd,
    name: "register"
  }, {
    path: "/user-agreement",
    component: _a15c397c,
    name: "user-agreement"
  }, {
    path: "/communities/add",
    component: _2bd2b3f2,
    name: "communities-add"
  }, {
    path: "/components/post-inline",
    component: _00f8dee4,
    name: "components-post-inline"
  }, {
    path: "/menu/posts",
    component: _7e228e9a,
    name: "menu-posts"
  }, {
    path: "/communities/:id",
    component: _3eb9aa64,
    name: "communities-id"
  }, {
    path: "/user/:username?",
    component: _2a7a032a,
    name: "user-username"
  }, {
    path: "/communities/:id/admin",
    component: _1419810e,
    name: "communities-id-admin"
  }, {
    path: "/communities/:id/edit",
    component: _ed7d3284,
    name: "communities-id-edit"
  }, {
    path: "/communities/:id/invite",
    component: _5143393d,
    name: "communities-id-invite"
  }, {
    path: "/communities/:id/members",
    component: _f22272d6,
    name: "communities-id-members"
  }, {
    path: "/communities/:id/requests",
    component: _ec3a0510,
    name: "communities-id-requests"
  }, {
    path: "/user/:username?/createprofile",
    component: _e44c9774,
    name: "user-username-createprofile"
  }, {
    path: "/user/:username?/edit",
    component: _1e3a2b81,
    name: "user-username-edit"
  }, {
    path: "/user/:username?/followers",
    component: _2d8977ae,
    name: "user-username-followers"
  }, {
    path: "/user/:username?/following",
    component: _0cb42fac,
    name: "user-username-following"
  }, {
    path: "/user/:username?/friends",
    component: _eeafed24,
    name: "user-username-friends"
  }, {
    path: "/communities/:id/admin/pending",
    component: _b60c435a,
    name: "communities-id-admin-pending"
  }, {
    path: "/communities/:id/admin/reported",
    component: _34e764c7,
    name: "communities-id-admin-reported"
  }, {
    path: "/user/:username?/posts/:id?",
    component: _01463ed2,
    name: "user-username-posts-id"
  }, {
    path: "/",
    component: _7a135eaa,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
