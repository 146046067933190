<template>
    <div class="container layout-default-container has-invisible-scroll">
        <TopNavbarNavigation v-if="showNavbarNavigation" />
        <TopNavbar v-else />

        <main class="has-padding-vertical-sm" role="main">
            <nuxt />
            <client-only>
                <PortalTarget name="main-overlay"></PortalTarget>
            </client-only>
        </main>

        <BottomNavbar v-if="showBotNavbar" />
        <div v-else-if="dontShowBotNavbar"></div>
        <NotAuthenticatedBottomNavbar v-else />
    </div>
</template>

<script>
import { mapState } from "vuex";

import TopNavbar from "~/components/TopNavbar.vue";
import TopNavbarNavigation from "~/components/TopNavbarNavigation.vue";
import BottomNavbar from "../components/BottomNavbar.vue";
import NotAuthenticatedBottomNavbar from "../components/NotAuthenticated/BottomNavbar.vue";

export default {
    name: "Default",
    components: {
        TopNavbar,
        TopNavbarNavigation,
        BottomNavbar,
        NotAuthenticatedBottomNavbar,
    },
    data() {
        return {};
    },
    computed: {
        ...mapState({
            showNavbarNavigation: function(state) {
                return (
                    state.showNavbarNavigation && this.$route.name !== "login"
                );
            },
        }),
        showBotNavbar() {
            return this.$auth.user && this.$route.name !== "login";
        },
        dontShowBotNavbar() {
            return (
                this.$route.name === "index" ||
                this.$route.name === "login" ||
                this.$route.name === "register" ||
                this.$route.name === "about" ||
                this.$route.name === "content-policy" ||
                this.$route.name === "user-agreement" ||
                this.$route.name === "privacy-policy"
            );
        },
    },
};
</script>

<style>
</style>
