window.$OneSignal = window.OneSignal = window.OneSignal || [];

OneSignal.push(['init', {
  "appId": "9b33c8ca-070e-45a5-9db4-739e569d35b9",
  "allowLocalhostAsSecureOrigin": true,
  "promptOptions": {
    "actionMessage": "Show push notifications about your friends and communities? If not that's cool too."
  },
  "welcomeNotification": {
    "disable": true
  },
  "notificationClickHandlerMatch": "origin"
}]);

export default function (ctx, inject) {
  inject('OneSignal', OneSignal)
}
