export default {
    data() {
        return { scrollX: 0, scrollY: 0, isBottom: false };
    },
    mounted() {
        if (process.browser) {
            window.addEventListener("scroll", this.onScrollHandler);
        }
    },
    beforeDestroy() {
        if (process.browser) {
            window.removeEventListener("scroll", this.onScrollHandler);
        }
    },
    methods: {
        onScrollHandler(e) {
            this.scrollX = window.scrollX;
            this.scrollY = window.scrollY;
            this.isBottom =
                Math.ceil(
                    Math.max(
                        window.pageYOffset,
                        document.documentElement.scrollTop,
                        document.body.scrollTop
                    )
                ) +
                    window.innerHeight ===
                document.documentElement.offsetHeight;
        },
        resetIsBottom() {
            this.isBottom = false;
        },
    },
};
