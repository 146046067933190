import {
    removeNamespace
} from "../helpers";

export const ACTIONS = {
    POST_FETCH_POSTS: "post/FETCH_POSTS",
    POST_FETCH_MORE_POSTS: "post/FETCH_MORE_POSTS",
    POST_FETCH_POST: "post/FETCH_POST",
    POST_FETCH_ALL_COMMENTS: "post/FETCH_ALL_COMMENTS",
    POST_FETCH_COMMENTS: "post/FETCH_COMMENTS",
    POST_CREATE_POST: "post/CREATE_POST",
    POST_EDIT_POST: "post/EDIT_POST",
    POST_COMMENT_ON_POST: "post/COMMENT_ON_POST",
    POST_COMMENT_ON_COMMENT: "post/COMMENT_ON_COMMENT",

    FEED_FETCH_POST: "feed/FETCH_POST",
    FEED_FETCH_POSTS: "feed/FETCH_POSTS",
    FEED_FETCH_MORE_POSTS: "feed/FETCH_MORE_POSTS",
    FEED_EDIT_POST: "feed/EDIT_POST",

    NOTIFICATIONS_FETCH_NOTIFICATIONS: "notifications/FETCH_NOTIFICATIONS",
    NOTIFICATIONS_FETCH_MORE_NOTIFICATIONS: "notifications/FETCH_MORE_NOTIFICATIONS",
    NOTIFICATIONS_MARK_ALL_AS_READ: "notifications/MARK_ALL_AS_READ",
    NOTIFICATIONS_MARK_AS_READ: "notifications/MARK_AS_READ",

    COMMUNITIES_FETCH_COMMUNITIES: "communities/FETCH_COMMUNITIES",
    COMMUNITIES_FETCH_COMMUNITY: "communities/FETCH_COMMUNITY",

    COMMUNITIES_CREATE_COMMUNITIES: "communities/CREATE_COMMUNITIES",
    COMMUNITIES_EDIT_COMMUNITIES: "communities/EDIT_COMMUNITIES",
    COMMUNITIES_JOIN_COMMUNITIES: "communities/JOIN_COMMUNITIES",
    COMMUNITIES_LEAVE_COMMUNITIES: "communities/LEAVE_COMMUNITIES",

    COMMENTS_FETCH_COMMENTS: "comments/FETCH_COMMENTS",
    COMMENTS_DELETE_COMMENTS: "comments/DELETE_COMMENTS",
    COMMENTS_UPDATE_COMMENT: "comments/UPDATE_COMMENT",
    COMMENTS_COMMENT_ON_POST: "comments/COMMENT_ON_POST",

    CHAT_SEND_MESSAGE: "chat/CHAT_SEND_MESSAGE",

};

// To be used externally, when a dispatch is used
export default ACTIONS;

// To be used internally, when creating a store's actions
export const _ACTIONS = {
    ...removeNamespace(ACTIONS),
};