// removes namespace that nuxt automagically takes care of for directory stores
export const removeNamespace = (types) => {
    function objectMap(object, mapFn) {
        return Object.keys(object).reduce(function(result, key) {
            result[key.substring(key.indexOf('_') + 1)] = mapFn(object[key])
            return result
        }, {})
    }
    return objectMap(types, function(v) {
        return v.substring(v.indexOf('/') + 1);
    })
}

export const stripNamespace = (s) => {
    return s.substring(s.indexOf('/') + 1)
}