<template>
  <div class="container">
    <div id="notfound">
      <div class="notfound">
        <div class="notfound-404">
          <h1 :style="{ backgroundImage: `url(${backgroundUrl})` }">Oops!</h1>
        </div>
        <h2 v-if="error.statusCode === 404">404 - Page not found</h2>
        <h2 v-else>An error occurred</h2>

        <p>The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
        <nuxt-link to="/">Home page</nuxt-link>
      </div>
    </div>

    <nuxt-link to="/">Home page</nuxt-link>
  </div>
</template>

<script>
import backgroundUrl from "~/assets/404bg.jpg";
export default {
    props: ["error"],
    data() {
        return { backgroundUrl };
    },
};
</script>

<style lang="css" scoped>
* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
}

#notfound {
  position: relative;
  height: 100vh;
}

#notfound .notfound {
  position: absolute;
  left: 50%;
  top: 35%;
  transform: translate(-50%, -50%);
}

.notfound {
  max-width: 410px;
  width: 100%;
  text-align: center;
}

.notfound .notfound-404 {
  height: 300px;
  position: relative;
  z-index: -1;
}

.notfound .notfound-404 h1 {
  font-family: 'Montserrat', sans-serif;
  font-size: 230px;
  margin: 0px;
  font-weight: 900;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  /* background: url('../404/bg.jpg') no-repeat; */
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-size: cover;
  background-position: center;
}


.notfound h2 {
  font-family: 'Montserrat', sans-serif;
  color: #000;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase;
  margin-top: 0;
}

.notfound p {
  font-family: 'Montserrat', sans-serif;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 20px;
  margin-top: 0px;
}

.notfound a {
  font-family: 'Montserrat', sans-serif;
  font-size: 14px;
  text-decoration: none;
  text-transform: uppercase;
  background: #0046d5;
  display: inline-block;
  padding: 15px 30px;
  border-radius: 40px;
  color: #fff;
  font-weight: 700;
  box-shadow: 0px 4px 15px -5px #0046d5;
}


@media only screen and (max-width: 767px) {
    .notfound .notfound-404 {
      height: 142px;
    }
    .notfound .notfound-404 h1 {
      font-size: 112px;
    }
}
</style>
