import { Database } from "@vuex-orm/core";
import User from "../models/User";
import Profile from "../models/Profile";
import Post, { postVuex } from "../models/Post";
import PostImage from "../models/PostImage";
import Comment, { commentVuex } from "../models/Comment";
import Community from "../models/Community";

const database = new Database();

database.register(User);
database.register(Profile);

database.register(Post, postVuex);
database.register(PostImage);

database.register(Comment, commentVuex);

database.register(Community);

export default database;
