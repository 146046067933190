import { Model } from "@vuex-orm/core";
import User from "./User";

export default class PostImage extends Model {
    static entity = "postimages";

    static fields() {
        return {
            id: this.attr(null),
            post_id: this.attr(null),
            image: this.attr(""),
            image_1024: this.attr(""),
            image_640: this.attr(""),
            image_320: this.attr(""),
            created: this.attr(""),
        };
    }
}
