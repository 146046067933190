import Vue from "vue";
import { _ACTIONS } from "./actions.types";
import { _MUTATIONS } from "./mutations.types";

import Comment from "@/models/Comment";
const {
    FETCH_COMMENTS,
    DELETE_COMMENTS,
    UPDATE_COMMENT,
    COMMENT_ON_POST,
} = _ACTIONS;
const {
    SET_IS_LOADING,
    SET_COMMENTS,
    SET_COMMENT,
    REMOVE_COMMENT,
    CREATE_COMMENT_ON_POST,
    CREATE_COMMENT_ON_COMMENT,
    DESTROY_COMMENT,
} = _MUTATIONS;

export const state = () => ({
    isLoading: false,
    // comments: [],
});

export const getters = {
    getCommentById: (state) => (id) => {
        function findById(data, id) {
            function iter(a) {
                if (a.id === id) {
                    result = a;
                    return true;
                }
                return Array.isArray(a.children) && a.children.some(iter);
            }
            var result;
            data.some(iter);
            return result;
        }
        return findById(state.comments, id);
    },
};

export const actions = {
    async [FETCH_COMMENTS]({ commit }, id) {
        try {
            const comments = (await this.$axios.$get(
                `api/posts/${id}/comments/`
            )).results;
            Comment.create({ data: comments, insert: ["users", "profiles"] });
        } catch (err) {
            console.error(
                "[store:comments:FETCH_COMMENTS]: ",
                err.response.data
            );
        }
    },
    async [UPDATE_COMMENT]({ commit, getters }, { id, comment }) {
        commit(SET_IS_LOADING, true);
        try {
            const data = await this.$axios.$patch(`api/comments/${id}/`, {
                comment,
            });
            Comment.update({
                data,
            });
        } catch (err) {
            console.error(
                "[store:comments:UPDATE_COMMENT]: ",
                err.response.data
            );
        } finally {
            commit(SET_IS_LOADING, false);
        }
    },
    async [DELETE_COMMENTS]({ commit, getters }, id) {
        try {
            await this.$axios.$delete(`api/comments/${id}/`);
        } catch (err) {
            console.error(
                "[store:comments:DELETE_COMMENT]: ",
                err.response.data
            );
        }
        let comment = getters.getCommentById(id);
        if (comment.children.length > 0) {
            commit(SET_COMMENT, {
                comment: getters.getCommentById(id),
                user: null,
                text: "[DELETED]",
            });
        } else {
            commit(DESTROY_COMMENT, id);
        }
    },
};

export const mutations = {
    [SET_IS_LOADING](state, isLoading) {
        state.isLoading = isLoading;
    },
    [SET_COMMENTS](state, comments) {
        state.comments = comments;
    },
    [SET_COMMENT](state, { user, comment, text }) {
        Vue.set(comment, "user", user);
        Vue.set(comment, "comment", text);
    },
    [DESTROY_COMMENT](state, id) {
        function destroyById(data, id) {
            function iter(comment) {
                if (comment.id === id) {
                    result = comment;
                    return true;
                }
                let v =
                    Array.isArray(comment.children) &&
                    comment.children.some(iter);
                if (v) {
                    let comments = comment.children.filter(
                        (child) => child.id != result.id
                    );
                    Vue.set(comment, "children", comments);
                }
            }
            var result;
            data.some(iter);
            return result;
        }
        destroyById(state.comments, id);
    },
    [REMOVE_COMMENT](state, id) {
        state.comments = state.comments.filter((comment) => comment.id !== id);
    },
    [CREATE_COMMENT_ON_COMMENT](state, { id, comment }) {
        function findById(data, id) {
            function iter(c) {
                if (c.id === id) {
                    result = c;
                    return true;
                }
                Array.isArray(c.children) && c.children.some(iter);
            }
            let result;
            data.some(iter);
            if (result) {
                result.children.unshift(comment);
            }
            return result;
        }
        findById(state.comments, id);
    },
    [CREATE_COMMENT_ON_POST](state, comment) {
        state.comments.unshift(comment);
    },
};
