import Vue from "vue";
import { _ACTIONS } from "./actions.types";
const { FETCH_POST, FETCH_POSTS, FETCH_MORE_POSTS, EDIT_POST } = _ACTIONS;
import { _MUTATIONS } from "./mutations.types";
const {
    SET_POST,
    SET_POSTS,
    ADD_POSTS_START,
    ADD_POSTS_END,
    REMOVE_POST,
    SET_NEXT,
    SET_IS_LOADING,
    SET_POST_RATING_AVERAGE,
    SET_POST_RATING_COUNT,
    SET_POST_RATING_USER,
} = _MUTATIONS;
import Post from "../models/Post";

export const state = () => ({
    // posts: [],
    isLoading: false,
    next: null,
});

export const getters = {};

export const actions = {
    async nuxtClientInit(
        { commit, dispatch },
        {
            app: { store },
        }
    ) {},
    async [FETCH_POST]({ commit }, id) {
        commit(SET_IS_LOADING, true);

        try {
            const data = await this.$axios.$get(`api/posts/${id}`);
            Post.insert({
                data: data,
                insert: ["users", "profiles"],
            });

            await Post.commit((state) => {
                state.next = data.next;
                state.previous = data.previous;
            });
        } catch (err) {
            console.error("[store:feed:FETCH_POST]: ", err.response.data);
        } finally {
            commit(SET_IS_LOADING, false);
        }
    },
    async [FETCH_POSTS]({ commit }) {
        commit(SET_IS_LOADING, true);

        try {
            // const data = await this.$axios.$get(`api/posts/`);
            const data = await this.$axios.$get(`api/me/feed/`);
            Post.create({
                data: data.results,
                insert: ["users", "profiles"],
            });

            await Post.commit((state) => {
                state.next = data.next;
                state.previous = data.previous;
            });
        } catch (err) {
            console.error("[store:feed:FETCH_POSTS]: ", err.response.data);
        } finally {
            commit(SET_IS_LOADING, false);
        }
    },
    async [EDIT_POST]({ commit }, { id, content, images }) {
        function images2Blob(images) {
            return images.map((i) => {
                return {
                    name: i.name,
                    blob: new Blob([i], {
                        type: i.type,
                    }),
                };
            });
        }

        const form = new FormData();
        form.append("content", content);
        const data = images2Blob(images);
        for (var i = 0; i < data.length; i++) {
            form.append("images", data[i].blob, data[i].name);
        }
        try {
            const data = await this.$axios.$patch(`api/posts/${id}/`, form);
            commit(SET_POST, data);
        } catch (err) {
            console.error("[store:feed:EDIT_POST]: ", err);
        }
    },
    async [FETCH_MORE_POSTS]({ commit, state }) {
        if (Post.getters("next") === null) return;

        commit(SET_IS_LOADING, true);

        try {
            const data = await this.$axios.$get(Post.getters("next"));
            Post.insert({
                data: data.results,
            });
            await Post.commit((state) => {
                state.next = data.next;
                state.previous = data.previous;
            });
        } catch (err) {
            console.error("[store:feed:FETCH_POSTS]: ", err.response.data);
        } finally {
            commit(SET_IS_LOADING, false);
        }
    },
};

export const mutations = {
    [SET_IS_LOADING](state, isLoading) {
        state.isLoading = isLoading;
    },
    [SET_POST](state, { id, content, images }) {
        let post = state.posts.find((post) => post.id === id);
        post.content = content;
        post.images = images;
    },
    [SET_POSTS](state, posts) {
        state.posts = posts;
    },
    [ADD_POSTS_START](state, posts) {
        state.posts = [...posts, ...state.posts];
    },
    [ADD_POSTS_END](state, posts) {
        state.posts = [...state.posts, ...posts];
    },
    [REMOVE_POST](state, post) {
        const i = state.posts.findIndex((p) => p.id === post.id);
        state.posts.splice(i, 1);
    },
    [SET_NEXT](state, next) {
        state.next = next;
    },
    [SET_POST_RATING_AVERAGE](state, { post, rating }) {
        Vue.set(post.rating, "average", rating);
    },
    [SET_POST_RATING_COUNT](state, { post, count }) {
        Vue.set(post.rating, "count", count);
    },
    [SET_POST_RATING_USER](state, { post, rating }) {
        Vue.set(post.rating, "user", rating);
    },
};
