import { Model } from "@vuex-orm/core";

export default class Profile extends Model {
    static entity = "profiles";

    static fields() {
        return {
            user_id: this.attr(null),
            cover: this.attr(null),
            about: this.attr(null),
            created: this.attr(""),
            is_private: this.attr(false),
            thumbnail: this.attr(null),
            photos: this.attr(false),
            isFriend: this.attr(false),
            isFollowing: this.attr(false),
            isFollower: this.attr(false),
            friendCount: this.attr(null),
            followerCount: this.attr(null),
            followingCount: this.attr(null),
        };
    }
}
