import { _ACTIONS } from "./actions.types";
import { _MUTATIONS } from "./mutations.types";

const {
    FETCH_COMMUNITIES,
    FETCH_COMMUNITY,
    CREATE_COMMUNITIES,
    EDIT_COMMUNITIES,
    JOIN_COMMUNITIES,
    LEAVE_COMMUNITIES,
} = _ACTIONS;
const {
    SET_COMMUNITIES,
    ADD_COMMUNITIES,
    SET_COMMUNITIES_LOADING,
} = _MUTATIONS;

import { ToastProgrammatic as Toast } from "buefy";

export const state = () => ({
    // communities: [],
    loading: false,
});

export const getters = {};

export const actions = {
    async [FETCH_COMMUNITIES]({ commit }) {
        commit(SET_COMMUNITIES_LOADING, true);

        try {
            const data = await this.$axios.$get(`api/communities/`);
            Community.create({
                data: data.results,
                insert: ["users", "profiles"],
            });
        } catch (err) {
            console.error(
                "[store:communities:FETCH_COMMUNITIES]: ",
                err.response.data
            );
        } finally {
            commit(SET_COMMUNITIES_LOADING, false);
        }
    },
    async [FETCH_COMMUNITY]({ commit }, id) {
        commit(SET_COMMUNITIES_LOADING, true);

        try {
            const data = await this.$axios.$get(`api/communities/${id}/`);
            Community.create({ data, insert: ["users", "profiles"] });
        } catch (err) {
            console.error("[store:communities:FETCH_COMMUNITY]: ", err);
        } finally {
            commit(SET_COMMUNITIES_LOADING, false);
        }
    },
    async [CREATE_COMMUNITIES]({ commit }, form) {
        try {
            const data = await this.$axios.$post(`api/communities/`, form);
            commit(ADD_COMMUNITIES, data.results);
        } catch (err) {
            console.error(
                "[store:communities:CREATE_COMMUNITIES]: ",
                err.response.data
            );
        }
    },
    async [JOIN_COMMUNITIES]({ commit }, id) {
        try {
            const data = await this.$axios.$post(`api/communities/${id}/join/`);
            Toast.open({
                message: `Request has been sent`,
                position: "is-bottom",
            });
        } catch (err) {
            console.error(
                "[store:communities:JOIN_COMMUNITIES]: ",
                err.response.data
            );
            Toast.open({
                message: err.response.data.detail,
                position: "is-bottom",
            });
        }
    },

    async [EDIT_COMMUNITIES]({ commit }, { id, form }) {
        try {
            const data = await this.$axios.$patch(
                `api/communities/${id}/`,
                form
            );
            commit(SET_COMMUNITIES, data.results);
        } catch (err) {
            console.error(
                "[store:communities:EDIT_COMMUNITIES]: ",
                err.response.data
            );
        }
    },

    async [LEAVE_COMMUNITIES]({ commit }, id) {
        try {
            // http://localhost:8000/api/communities/{id}/remove_user/{username}/
            //leave the community. Remove User.

            const data = await this.$axios.$post(
                `api/communities/${id}/leave/`
            );
        } catch (error) {
            console.error(
                "[store:communities:LEAVE_COMMUNITIES]: ",
                error.response.data
            );
        }
    },
};

export const mutations = {
    [SET_COMMUNITIES](state, communities) {
        state.communities = communities;
    },
    [ADD_COMMUNITIES](state, community) {
        state.communities.push(community);
    },
    [SET_COMMUNITIES_LOADING](state, loading) {
        state.loading = loading;
    },
};
