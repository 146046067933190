<template lang="html">
  <nav
    :class="{ 'has-navbar-bottom-shadow': isShadow }"
    class="navbar is-fixed-bottom is-white"
    role="navigation"
  >
    <nav class="level is-mobile is-overlay">
      <div
        :class="[isActive.index ? 'has-text-primary' : 'has-text-grey-dark']"
        class="level-item has-text-centered"
        style="line-height: 1;"
      >
        <nuxt-link :to="{ path: '/' }" tag="span" replace exact>
          <span class="icon is-medium">
            <i class="mdi mdi-18px mdi-home-outline" :class="{ 'is-clickable': !isActive.index }">
              <span class="is-size-7-mobile">Home</span>
            </i>
          </span>
        </nuxt-link>
      </div>

      <div
        :class="[isActive.discover ? 'has-text-primary' : 'has-text-grey-dark']"
        class="level-item has-text-centered"
        style="line-height: 1;"
      >
        <nuxt-link :to="{ name: 'discover' }" tag="span" replace>
          <span class="icon is-medium">
            <i class="mdi mdi-18px mdi-compass-outline" :class="{ 'is-clickable': !isActive.discover }">
              <span class="is-size-7-mobile">Discover</span>
            </i>
          </span>
        </nuxt-link>
      </div>

      <div
        :class="[isActive.communities ? 'has-text-primary' : 'has-text-grey-dark']"
        class="level-item has-text-centered"
        style="line-height: 1;"
      >
        <nuxt-link :to="{ name: 'communities' }" tag="span" replace>
          <span class="icon is-medium">
            <i class="mdi mdi-18px mdi-account-group" :class="{ 'is-clickable': !isActive.communities }">
              <span class="is-size-7-mobile">Communities</span>
            </i>
          </span>
        </nuxt-link>
      </div>

      <div
        :class="[
          isActive.notifications ? 'has-text-primary' : 'has-text-grey-dark',
        ]"
        class="level-item has-text-centered"
        style="line-height: 1;"
      >
        <nuxt-link :to="{ name: 'notifications' }" tag="span" replace>
          <span class="icon is-medium">
            <i class="mdi mdi-18px mdi-bell-outline" :class="{ 'is-clickable': !isActive.notifications }">
              <span class="is-size-7-mobile">Notifications</span>
            </i>
          </span>
          <span
            v-if="unseenCount > 0"
            :data-badge="unseenCount"
            class="badge is-badge-danger is-badge-outlined"
          ></span>
        </nuxt-link>
      </div>

      <div
        :class="[isActive.menu ? 'has-text-primary' : 'has-text-grey-dark']"
        class="level-item has-text-centered"
        style="line-height: 1;"
      >
        <nuxt-link :to="{ name: 'menu' }" tag="span" replace>
          <span class="icon is-medium">
            <i class="mdi mdi-18px mdi-menu" :class="{ 'is-clickable': !isActive.menu }">
              <span class="is-size-7-mobile">Menu</span>
            </i>
          </span>
        </nuxt-link>
      </div>
    </nav>
  </nav>
</template>

<script>
import { mapState } from "vuex";

import scrollMixin from "~/mixins/scrollMixin.js";

export default {
    mixins: [scrollMixin],
    data() {
        return {
            isActive: {
                index: false,
                discover: false,
                communities: false,
                notifications: false,
                menu: false,
            },
        };
    },
    methods: {
        setActive(name) {
            for (var key in this.isActive) {
                if (this.isActive.hasOwnProperty(key)) {
                    if (key === name) {
                        this.isActive[key] = true;
                    } else {
                        this.isActive[key] = false;
                    }
                }
            }
        },
    },
    computed: {
        isShadow: function() {
            return this.scrollY > 20;
        },
        ...mapState({
            unseenCount: (state) => state.notifications.unseenCount,
        }),
    },
    watch: {
        ["$route.name"]: {
            handler: function() {
                this.setActive(this.$route.name);
            },
            immediate: true,
        },
    },
};
</script>

<style lang="css" scoped>
.has-navbar-bottom-shadow {
    /* box-shadow: 0px -8px 4px -3px hsla(0, 0%, 96%, 1); */
    box-shadow: 0px -1px 5px 0px hsla(0, 0%, 0%, 0.1);
}
.badge {
    position: relative;
    top: 0rem;
    right: 0.8rem;
    font-weight:700;
}
/* quickly override to fit */
::v-deep .is-size-7-mobile {
  font-size: 0.55rem !important;
}
</style>
