import Vue from "vue";
import moment from "moment";
import { _ACTIONS } from "./actions.types";
const { SEND_MESSAGE } = _ACTIONS;

import { _MUTATIONS } from "./mutations.types";

const { SET_USER, SET_MESSAGE } = _MUTATIONS;

export const state = () => ({
    selectedUser: null,
    messages: {},
});

//add a message to the user conversation
//messages will be a dicitonary whose keys are user id
//value is object, the message object
//msg object is the text. user object
// messages = {
//     user_id: [{
//         text: '...',
//         user: {
//             'UserObj'
//         }
//     }]
// }

export const getters = {
    getSelectedUserMessages(state) {
        if (state.selectedUser) {
            return state.messages[`${state.selectedUser.id}`];
        } else {
            return [];
        }
    },
};

export const actions = {
    async [SEND_MESSAGE]({ state, commit }, text) {
        if (state.selectedUser && text.length > 0) {
            let now = moment().calendar(null, {
                sameDay: "[Today at] LT",
                nextDay: "[Tomorrow]",
                nextWeek: "dddd",
                lastDay: "[Yesterday at] LT",
                lastWeek: "[Last] dddd",
                sameElse: "DD/MM/YYYY",
            });
            let user = {};
            Object.assign(user, state.selectedUser);
            commit(SET_MESSAGE, [
                ...state.messages[`${state.selectedUser.id}`],
                {
                    user: user,
                    text,
                    timestamp: now,
                },
            ]);
        }
    },
};

export const mutations = {
    [SET_USER](state, user) {
        state.selectedUser = user;
    },
    [SET_MESSAGE](state, text) {
        if (state.selectedUser) {
            Vue.set(state.messages, `${state.selectedUser.id}`, text);
        }
    },
};
