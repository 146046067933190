import Vue from "vue";

export const state = () => ({
    socket: {
        isConnected: false,
        message: "",
        reconnectError: false,
    },

    // {
    //     id: '',
    //     username: '',
    //     ...
    // }
    selectedUser: null,

    // {
    //     user_id: room_id
    // }
    userRooms: {},

    // {
    //     room_id: [{ // Message object
    //         id: '',
    //         message: '',
    //         created_by: {...}
    //     },...]
    // }
    roomMessages: {},

    isTyping: false,
});

export const getters = {
    getSelectedRoomID: (state) => {
        return state.userRooms[state.selectedUser.id] || null;
    },
    getSelectRoomMessages: (state) => {
        if (state.selectedUser) {
            return (
                state.roomMessages[state.userRooms[state.selectedUser.id]] || []
            );
        } else {
            return [];
        }
    },
    getSelectRoomMessagesReverse: (state) => {
        if (state.selectedUser) {
            return (
                state.roomMessages[state.userRooms[state.selectedUser.id]] || []
            )
                .slice()
                .reverse();
        } else {
            return [];
        }
    },
};

export const actions = {
    sendMessage(context, message) {
        console.log("sendMessage");
        console.log("message", message);
    },
    typingAction({ state, commit }, { typing, user_id }) {
        if (state.selectedUser.id === user_id) {
            commit("SET_ISTYPING", { typing });
        }
    },
};

export const mutations = {
    SOCKET_ONOPEN(state, event) {
        console.log("SOCKET_ONOPEN");
        Vue.prototype.$socket = event.currentTarget;
        state.socket.isConnected = true;
    },
    SOCKET_ONCLOSE(state, event) {
        console.log("SOCKET_ONCLOSE");
        state.socket.isConnected = false;
    },
    SOCKET_ONERROR(state, event) {
        console.log("SOCKET_ONERROR");
        console.error(state, event);
    },
    // default handler called for all methods
    SOCKET_ONMESSAGE(state, message) {
        console.log("SOCKET_ONMESSAGE");
        console.log(message);
        state.socket.message = message;
    },
    // mutations for reconnect methods
    SOCKET_RECONNECT(state, count) {
        console.log("SOCKET_RECONNECT");
        console.info(state, count);
    },
    SOCKET_RECONNECT_ERROR(state) {
        console.log("SOCKET_RECONNECT_ERROR");
        state.socket.reconnectError = true;
    },

    SET_USER(state, user) {
        state.selectedUser = user;
    },
    SET_ROOM(state, { room_id, user_id }) {
        state.userRooms[user_id] = room_id;
    },
    SET_ISTYPING(state, { typing }) {
        state.isTyping = typing;
    },
    SET_MESSAGES(state, messages) {
        if (state.selectedUser) {
            Vue.set(
                state.roomMessages,
                state.userRooms[state.selectedUser.id],
                messages
            );
        }
    },
    ADD_MESSAGE(state, { message }) {
        if (state.selectedUser) {
            const room_id = state.userRooms[state.selectedUser.id];
            let messages = state.roomMessages[room_id] || [];
            messages.unshift(message);
            Vue.set(state.roomMessages, `${room_id}`, messages);
        }
    },
};
