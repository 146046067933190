import { removeNamespace } from "../helpers";

export const MUTATIONS = {
    POST_SET_POST_LOADING: "post/SET_POST_LOADING",
    POST_SET_COMMENTS_LOADING: "post/SET_COMMENTS_LOADING",
    POST_SET_POSTS: "post/SET_POSTS",
    POST_SET_NEXT_URL_POSTS: "post/SET_NEXT_URL_POSTS",
    POST_ADD_POST: "post/ADD_POST",
    POST_ADD_POSTS: "post/ADD_POSTS",
    POST_SET_POST: "post/SET_POST",
    POST_SET_COMMENTS: "post/SET_COMMENTS",
    POST_SET_ERRORS: "post/SET_ERRORS",

    FEED_SET_POST: "feed/SET_POST",
    FEED_SET_POSTS: "feed/SET_POSTS",
    FEED_ADD_POSTS_START: "feed/ADD_POSTS_START",
    FEED_ADD_POSTS_END: "feed/ADD_POSTS_END",
    FEED_REMOVE_POST: "feed/REMOVE_POST",
    FEED_SET_NEXT: "feed/SET_NEXT",
    FEED_SET_IS_LOADING: "feed/SET_IS_LOADING",
    FEED_SET_POST_RATING_AVERAGE: "feed/SET_POST_RATING_AVERAGE",
    FEED_SET_POST_RATING_COUNT: "feed/SET_POST_RATING_COUNT",
    FEED_SET_POST_RATING_USER: "feed/SET_POST_RATING_USER",

    NOTIFICATIONS_SET_NOTIFICATIONS: "notifications/SET_NOTIFICATIONS",
    NOTIFICATIONS_ADD_NOTIFICATIONS: "notifications/ADD_NOTIFICATIONS",
    NOTIFICATIONS_REMOVE_NOTIFICATION: "notifications/REMOVE_NOTIFICATION",
    NOTIFICATIONS_SET_NEXT: "notifications/SET_NEXT",
    NOTIFICATIONS_SET_UNREAD: "notifications/SET_UNREAD",
    NOTIFICATIONS_SET_IS_LOADING: "notifications/SET_IS_LOADING",
    NOTIFICATIONS_SET_UNREAD_COUNT: "notifications/SET_UNREAD_COUNT",
    NOTIFICATIONS_SET_UNSEEN_COUNT: "notifications/SET_UNSEEN_COUNT",

    COMMUNITIES_SET_COMMUNITIES: "communities/SET_COMMUNITIES",
    COMMUNITIES_ADD_COMMUNITIES: "communities/ADD_COMMUNITIES",
    COMMUNITIES_UPDATE_COMMUNITIES: "communities/UPDATE_COMMUNITIES",
    COMMUNITIES_SET_COMMUNITIES_LOADING: "communities/SET_COMMUNITIES_LOADING",

    COMMENTS_SET_IS_LOADING: "comments/SET_IS_LOADING",
    COMMENTS_SET_COMMENTS: "comments/SET_COMMENTS",
    COMMENTS_SET_COMMENT: "comments/SET_COMMENT",
    COMMENTS_REMOVE_COMMENT: "comments/REMOVE_COMMENT",
    COMMENTS_EDIT_COMMENT: "comments/EDIT_COMMENT",
    COMMENTS_DESTROY_COMMENT: "comments/DESTROY_COMMENT",
    COMMENTS_CREATE_COMMENT_ON_POST: "comments/CREATE_COMMENT_ON_POST",
    COMMENTS_CREATE_COMMENT_ON_COMMENT: "comments/CREATE_COMMENT_ON_COMMENT",

    CHAT_SET_USER: "chat/SET_USER",
    CHAT_SET_MESSAGE: "chat/SET_MESSAGE",
};

// To be used externally, when a commit is used
export default MUTATIONS;

// To be used internally, when creating a store's mutations
export const _MUTATIONS = {
    ...removeNamespace(MUTATIONS),
};
