import { Model } from "@vuex-orm/core";
import User, { CommunityUser } from "./User";

export const postVuex = {
    state: {
        next: null,
        previous: null,
    },
    mutations: {
        setNext(state, next) {
            state.next = next;
        },
        setPrevious(state, previous) {
            state.previous = previous;
        },
    },
    getters: {
        next: (state) => state.next,
        previous: (state) => state.previous,
    },
};

export default class Community extends Model {
    static entity = "communities";

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            member_ids: this.attr(null),
            moderator_ids: this.attr(null),
            name: this.attr(""),
            is_private: this.attr(true),
            about: this.attr(""),
            thumbnail: this.attr(""),
            cover: this.attr(""),
            created: this.attr(""),
            created_by: this.belongsTo(User, "user_id"),
            members: this.hasManyBy(User, "member_ids"),
            moderators: this.hasManyBy(User, "moderator_ids"),
            privacy: this.attr(""),
            isMember: this.attr(false),
        };
    }
}
