<!--
Is causing:
- [Vue warn]: The client-side rendered virtual DOM tree is not matching server-rendered content. This is likely caused by incorrect HTML markup, for example nesting block-level elements inside <p>, or missing <tbody>. Bailing hydration and performing full client-side render.
-->
<template lang="html">
  <nav
    :class="{'has-navbar-top-shadow': isShadow}"
    class="navbar is-fixed-top is-white"
    role="navigation"
    aria-label="main navigation"
  >
    <div class="navbar-brand">
      <nuxt-link :to="{ name: 'index' }" tag="a" exact>
        <div class="navbar-item">
          <!-- <h3 class="card-header-title has-text-black">SAFESPACE</h3> -->
          <img
            src="~assets/skryit_logo_text_landscape.svg"
            width="105"
            height="30"
          />
        </div>
      </nuxt-link>
        <span v-if="!this.$auth.loggedIn"
            class="navbar-burger burger"
            data-target="navbarMenu"
            @click="showHamburger = !showHamburger"
            :class="{ 'is-active' : showHamburger }"
        >
            <span></span>
            <span></span>
            <span></span>
        </span>
    </div>
    <div
        id="navbarMenu"
        class="navbar-menu"
        :class="{ 'is-active' : showHamburger }"
    >
    <!-- <div class="navbar-end">
      <div class="navbar-item">
        <div class="buttons">
          <nuxt-link class="button is-outlined" to="/login">
                    <span>Login</span>
                </nuxt-link>
          <nuxt-link class="button is-outlined" to="/register">
                    <span>Register</span>
                </nuxt-link>
                   <nuxt-link
                    class="button is-outlined"
                    to="/communities"
                >
                    <span>Communities</span>
                </nuxt-link>
        </div>
      </div>
    </div> -->
        <div v-if="!this.$auth.loggedIn" class="navbar-end" @click.stop="showHamburger = false">
            <span class="navbar-item">
                <nuxt-link class="button is-outlined" to="/discover">
                    <span>Discover</span>
                </nuxt-link>
            </span>
            <span class="navbar-item" id="communities">
                <nuxt-link
                    class="button is-outlined"
                    to="/communities"
                >
                    <span>Communities</span>
                </nuxt-link>
            </span>
        </div>
    </div>
  </nav>
</template>

<script>
import scrollMixin from "~/mixins/scrollMixin.js";

export default {
    mixins: [scrollMixin],
    data() {
        return {
            showHamburger: false,
        };
    },
    computed: {
        isShadow: function() {
            return this.scrollY > 20;
        },
    },
};
</script>

<style lang="css" scoped>
.has-navbar-top-shadow {
    /* box-shadow: 0px 8px 4px -3px hsla(0, 0%, 96%, 1); */
    box-shadow: 0px 1px 5px 0px hsla(0, 0%, 0%, 0.1);
}

#communities {
    padding-right: 2rem;
}
</style>
