import Vue from "vue";
import VuexORM from "@vuex-orm/core";
import database from "../database";

export const plugins = [VuexORM.install(database)];

export const state = () => ({
    showNavbarNavigation: false,
    metaPreview: {},
});

export const actions = {
    // https://github.com/potato4d/nuxt-client-init-module
    // async nuxtClientInit({ commit, dispatch }, {app: { store }}) {
    async nuxtClientInit({ commit, dispatch }) {},
};

export const mutations = {
    ["SET_SHOW_NAVBAR_NAVIGATION"](state, v) {
        state.showNavbarNavigation = v;
    },
    SET_METAPREVIEW(state, data) {
        Vue.set(state, "metaPreview", data);
    },
};
