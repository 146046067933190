export default(context) => {
  if (process.browser) {
    setTimeout(() => {
      context.store.dispatch('nuxtClientInit', context)

      const actions = Object.keys(context.store._actions).filter(k => k.includes('/nuxtClientInit'))
      actions.forEach(function(a) {
        context.store.dispatch(a, context)
      });
    }, 0)
  }
}
