import { Model } from "@vuex-orm/core";
import User from "./User";

export const commentVuex = {
    state: {
        next: null,
        previous: null,
    },
    mutations: {
        setNext(state, next) {
            state.next = next;
        },
        setPrevious(state, previous) {
            state.previous = previous;
        },
    },
    getters: {
        next: (state) => state.next,
        previous: (state) => state.previous,
    },
};

export default class Comment extends Model {
    static entity = "comments";

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            parent_id: this.attr(null),
            level: this.attr(0),
            parent: this.attr(null),
            comment: this.attr(""),
            children: this.hasMany(Comment, "parent_id"),
            user: this.belongsTo(User, "user_id"),
            created: this.attr(""),
            modified: this.attr(""),
            rating: this.attr({ user: 0, count: 0, average: 0 }), // FIXME: They need models
        };
    }
}
