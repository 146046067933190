<template lang="html">
  <nav
    :class="{'has-navbar-top-shadow': isShadow}"
    class="navbar is-fixed-top is-white"
    role="navigation"
    aria-label="navigation"
  >
    <div class="level is-mobile is-overlay has-margin-top-sm is-aligned-items-center">
      <div
        class="level-item has-text-centered is-narrow"
        style="line-height: 1;"
        @click="goBack()"
      >
        <b-icon
          icon="chevron-left"
          size="is-large"
          custom-size="mdi-36px"
        ></b-icon>
      </div>
      <div class="level-item has-text-centered" style="line-height: 1;">
        <p class="title is-size-4">{{title}}</p>
      </div>
      <div class="level-item has-text-centered is-narrow" style="line-height: 1;">
          <b-icon
            size="is-large"
            custom-size="mdi-36px"
          ></b-icon>
      </div>
  </div>
  </nav>
</template>

<script>
import scrollMixin from "~/mixins/scrollMixin.js";

export default {
    mixins: [scrollMixin],
    props: {
        title: {
            type: String,
        },
    },
    computed: {
        isShadow: function() {
            return this.scrollY > 20;
        },
    },
    methods: {
        goBack() {
            window.history.length > 1
                ? this.$router.go(-1)
                : this.$router.push("/");
        },
    },
};
</script>

<style lang="css" scoped>
.has-navbar-top-shadow {
    /* box-shadow: 0px 8px 4px -3px hsla(0, 0%, 96%, 1); */
    box-shadow: 0px 1px 5px 0px hsla(0, 0%, 0%, 0.1);
}
</style>
