export default async function({ $auth }) {
    if (!$auth.loggedIn) return;

    window.OneSignal.push(async () => {
        if (!window.OneSignal.isPushNotificationsSupported()) return;

        window.OneSignal.on("subscriptionChange", async (isSubscribed) => {
            if (isSubscribed) {
                if ($auth.loggedIn) {
                    await window.OneSignal.setExternalUserId($auth.user.id);
                }
            }
        });

        const isPushEnabled = await window.OneSignal.isPushNotificationsEnabled();
        if (isPushEnabled) {
            const externalUserId = await window.OneSignal.getExternalUserId();
            if (externalUserId !== $auth.user.id) {
                await window.OneSignal.setExternalUserId($auth.user.id);
            }
        }

        // window.OneSignal.push(() => {
        //     window.OneSignal.on("notificationDisplay", function(event) {});
        // });
    });
}
