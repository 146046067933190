import { Model } from "@vuex-orm/core";
import User from "./User";
import PostImage from "./PostImage";
import Community from "./Community";

export const postVuex = {
    state: {
        next: null,
        previous: null,
    },
    mutations: {
        setNext(state, next) {
            state.next = next;
        },
        setPrevious(state, previous) {
            state.previous = previous;
        },
    },
    getters: {
        next: (state) => state.next,
        previous: (state) => state.previous,
    },
};

export default class Post extends Model {
    static entity = "posts";

    static fields() {
        return {
            id: this.attr(null),
            user_id: this.attr(null),
            content: this.attr(""),
            images: this.hasMany(PostImage, "post_id"),
            view_count: this.attr(0),
            created: this.attr(""),
            is_private: this.attr(false),
            created_by: this.belongsTo(User, "user_id"),
            comment_count: this.attr(0),
            type_name: this.attr(null),
            ref: this.attr({
                id: null,
                name: null,
            }),
            rating: this.attr({
                user: 0,
                count: 0,
                average: 0,
            }), // FIXME: They need models
        };
    }
}
