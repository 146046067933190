import { _ACTIONS } from "./actions.types";
import { _MUTATIONS } from "./mutations.types";

const {
    FETCH_NOTIFICATIONS,
    FETCH_MORE_NOTIFICATIONS,
    MARK_ALL_AS_READ,
    MARK_AS_READ,
} = _ACTIONS;

const {
    SET_IS_LOADING,
    SET_NOTIFICATIONS,
    ADD_NOTIFICATIONS,
    REMOVE_NOTIFICATION,
    SET_NEXT,
    SET_UNREAD,
    SET_UNREAD_COUNT,
    SET_UNSEEN_COUNT,
} = _MUTATIONS;

export const state = () => ({
    isLoading: false,
    notifications: [],
    next: null,
    unreadCount: 0,
    unseenCount: 0,
});

export const getters = {};

export const actions = {
    async nuxtClientInit({ commit, dispatch }) {
        if (this.$auth.user) {
            // dispatch(FETCH_NOTIFICATIONS);
        }
    },
    async [FETCH_NOTIFICATIONS]({ commit }) {
        commit(SET_IS_LOADING, true);

        try {
            const data = await this.$axios.$get(
                `api/me/notifications/?mark_seen=`
            );
            commit(SET_NOTIFICATIONS, data.results);
            commit(SET_UNREAD_COUNT, data.unread);
            commit(SET_UNSEEN_COUNT, data.unseen);
            commit(SET_NEXT, data.next);
        } catch (err) {
            console.error(
                "[store:notifications:FETCH_NOTIFICATIONS]: ",
                err.response.data
            );
        } finally {
            commit(SET_IS_LOADING, false);
        }
    },
    async [FETCH_MORE_NOTIFICATIONS]({ commit, getters, state }) {
        if (state.next === null) {
            return;
        }

        commit(SET_IS_LOADING, true);

        try {
            const data = await this.$axios.$get(state.next);
            commit(ADD_NOTIFICATIONS, data.results);
            commit(SET_NEXT, data.next);
        } catch (err) {
            console.error(
                "[store:notifications:FETCH_MORE_NOTIFICATIONS]: ",
                err.response.data
            );
        } finally {
            commit(SET_IS_LOADING, false);
        }
    },
    async [MARK_ALL_AS_READ]({ commit, state }) {
        try {
            const data = await this.$axios.$post(
                `api/me/notifications/mark_read/`,
                { mark_all_read: true }
            );
            commit(SET_NOTIFICATIONS, data.results);
            commit(SET_UNREAD_COUNT, data.unread);
            commit(SET_UNSEEN_COUNT, data.unseen);
            commit(SET_NEXT, data.next);
        } catch (err) {
            console.error(
                "[store:notifications:MARK_ALL_AS_READ]: ",
                err.response.data
            );
        } finally {
            // state.notifications.map((n) => {
            //     commit(SET_UNREAD, {
            //         id: n.id,
            //         v: false,
            //     });
            // });
            // commit(SET_UNREAD_COUNT, 0);
        }
    },
    async [MARK_AS_READ]({ commit, state }, n) {
        try {
            const data = await this.$axios.$post(
                `api/me/notifications/mark_read/`,
                { notification_group_id: n.id }
            );
            commit(SET_NOTIFICATIONS, data.results);
            commit(SET_UNREAD_COUNT, data.unread);
            commit(SET_UNSEEN_COUNT, data.unseen);
            commit(SET_NEXT, data.next);
        } catch (err) {
            console.error(
                "[store:notifications:MARK_AS_READ]: ",
                err.response.data
            );
        } finally {
            // commit(SET_UNREAD, {
            //     id: n.id,
            //     v: false,
            // });
            // commit(SET_UNREAD_COUNT, state.unreadCount - 1);
        }
    },
};

export const mutations = {
    [SET_IS_LOADING](state, isLoading) {
        state.isLoading = isLoading;
    },
    [SET_NOTIFICATIONS](state, notifications) {
        state.notifications = notifications;
    },
    [ADD_NOTIFICATIONS](state, notifications) {
        state.notifications = [...state.notifications, ...notifications];
    },
    [REMOVE_NOTIFICATION](state, id) {
        let i = state.notifications.findIndex((n) => n.id === id);
        state.notifications.splice(i, 1);
    },
    [SET_NEXT](state, next) {
        state.next = next;
    },
    [SET_UNREAD](state, { id, v }) {
        const n = state.notifications.find((n) => n.id === id);
        n.unread = v;
    },
    [SET_UNREAD_COUNT](state, count) {
        state.unreadCount = count;
    },
    [SET_UNSEEN_COUNT](state, count) {
        state.unseenCount = count;
    },
};
