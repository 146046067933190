import { Model } from "@vuex-orm/core";
import Profile from "./Profile";

export default class User extends Model {
    static entity = "users";

    static fields() {
        return {
            id: this.attr(null),
            username: this.attr(""),
            name: this.attr(""),
            profile: this.hasOne(Profile, "user_id"),
        };
    }
}
